/* src/Slider.css */
.slider {
    height: 100vh;
    display: block;
    justify-content: center;
    align-items: center;
}

.arrow-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px;
    font-size: 24px;
    cursor: pointer;
    border-radius: 100px;
}

.arrow-button.left {
    left: 10px;
}

.arrow-button.right {
    right: 10px;
}

.fade-out {
    animation: fadeOut 0.5s forwards;
}

@keyframes fadeOut {
    to {
        opacity: 0;
    }
}
