.music-player {
    display: flex;
    align-items: center;
    background-color: #cfcfcf;
    padding: 10px;
    border-radius: 50px;
    max-width: 100%;
    margin-bottom: 10px;
}

.song-cover {
    width: 60px;
    height: 60px;
    margin-right: 10px;
    border-radius: 30px;
}

.song-info {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
}

.song-title, .song-artist {
    font-family: Arial;
    font-size: 14px;
    margin: 0;
    padding: 0;
}

.react-audio-player {
    flex-grow: 1;
}
