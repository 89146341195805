.walk1-container {
    width: 100%;
    min-height: 100vh;
    padding: 50px;
    box-sizing: border-box;
    background: linear-gradient(to bottom, rgb(63, 40, 57), rgb(81, 70, 131))
}
.walk1-background {
    background-color: rgba(211, 211, 211, 0.5);
    padding: 5px;
    border-radius: 20px;
}
.walk2-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom, rgb(104, 67, 94), orange);
    min-height: 100vh;
}

.left-column2, .right-column2 {
    display: flex;
    margin: 10px;
    flex-direction: column;
    align-items: center;
    width: 30%;
    background-color: rgba(211, 211, 211, 0.5);
    padding: 5px;
    border-radius: 20px;
}

.block__text2 {
    width: 100%;
    text-align: center;
}

.image-container2 {
    position: relative;
    width: 100%;
}

.main-image2, .big-image2 {
    width: 100%;
    border-radius: 20px;
}

.caption {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 5px;
    font-size: 12px;
    border-radius: 10px;
    font-family: 'Trebuchet MS';
}

.top-image-container2 {
    position: relative;
    width: 100%;
}

.small-images-container2 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
}

.small-image2 {
    width: 49%;
    border-radius: 20px;
}

@media screen and (max-width: 768px) {
    .walk2-container {
        flex-direction: column;
    }

    .left-column2, .right-column2 {
        width: 100%;
    }

    .small-image2 {
        width: 48%;
    }
}
