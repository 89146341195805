.App {
    display: block;
}

.horizontal-container {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.vertical-group {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.vertical-photo-container {
    position: relative;
    margin-bottom: 20px;
}

.top-right-text {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(255, 255, 255, 0.7);
    padding: 5px;
    border-radius: 5px;
}

.bottom-left-text {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background: rgba(255, 255, 255, 0.7);
    padding: 5px;
    border-radius: 5px;
}
