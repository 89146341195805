.difference1-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px;
    text-align: center;
    background-image: url('assets/Difference4.jpg');
    background-size: cover;
    background-position: center;
}

.difference1-header {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 30px;
    font-weight: bold;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.difference1-images, .difference1-video {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin-top: 50px;
}

.difference1-images {
    margin-bottom: 20px;
}

.media-element-df1 {
    height: 40vh;
    margin: 0 10px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border: 5px solid white; /* Добавлена белая обводка */
}
