.walk3-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color:bisque;
    min-height: 100vh;
}

.left-column {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 30%;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    margin: 2%;
}

.top-image-container {
    position: relative;
    width: 50%;
}

.big-image {
    width: 100%;
    height: auto;
}

.caption {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 5px;
    font-size: 12px;
}

.small-images-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    margin-left: 5px;
}

.small-image {
    width: 100%;
    margin: 5px;
    height: calc(50% - 2.5px);
}

.big-image, .small-image, .main-image {
    border-radius: 20px;
}

.right-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    padding: 10px;
    margin: 2%;
}

.block__text {
    width: 100%;
    text-align: center;
}

.image-container {
    position: relative;
    width: 100%;
}

.main-image {
    width: 46%;
    margin: 2%;
}

@media screen and (max-width: 768px) {
    .walk3-container {
        flex-direction: column;
    }

    .left-column, .right-column {
        width: 100%;
    }

    .small-image, .main-image {
        width: 100%;
    }
}
