.new-year {
    height: max(10vw, fit-content);
    text-align: center;
    padding: 20px;
    background-color: rgb(0, 153, 195);
    min-height: 100vh;
}

.new-year__container {
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: center;
}

.new-year__left,
.new-year__right {
    width: 20%;
    margin: 20px;
    display: flex;
    flex-direction: column;
}

.new-year__left > * {
    margin-bottom: 20px;
}

.image-container {
    position: relative;
}

.block__image {
    height: auto;
}

.tree-icon {
    position: absolute;
    right: -30%;
    top: 0;
    width: auto;
    height: 100%; /* Adjust the size of the tree as needed */
}
h1 {
    font-family: 'Trebuchet MS';
    color:aliceblue;
}
