.ship-container {
    height: 100vh;
    background: linear-gradient(to top, rgb(0, 96, 125), white);
}
.ship-image {
    height: 50vh;
    margin: 15px;
    border-radius: 20px;
}
.ship-images-container {
    margin-top: 10%;
    margin-inline: auto;
    padding: 10px;
    border-radius: 20px;
    width: fit-content;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
.ship-wave{
    position: absolute;
    bottom: 0;
    height: 30vh;
}