.full-page {
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 40px;
    box-sizing: border-box;
}

.overlay {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
}

.main-header {
    color: white;
    text-align: center;
    margin: 20px 0;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    padding: 20px;
    font-size: 2.5em;
}

.text-box {
    color: white;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    padding: 20px;
    font-size: 2.5em;
    max-width: 80%;
}

.top-left-box {
    align-self: flex-start;
}

.bottom-right-box {
    align-self: flex-end;
}
