.poems__container {
    min-height: 100vh; /* Используем min-height вместо height */
    display: flex;
    justify-content: center; /* Центрируем контейнер по горизонтали */
    align-items: flex-start; /* Оставляем элементы прикреплёнными к верхней части контейнера */
    gap: 20px;
    padding: 20px;
    background-color: #f0f8ff; /* Общий цвет фона */
    box-sizing: border-box;
    min-height: 100vh;
    overflow-y: auto; /* Добавляем прокрутку для контейнера */
}

.poems__left, .poems__right {
    width: 50%;
    max-width: 600px;
    margin: 10px;
    box-sizing: border-box;
    margin-left: 0;
}

.poems__block__text {
    font-size: 20px;
    color: #333;
    line-height: 1.5;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #a0cfee;
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
}

.poems__image__container {
    position: relative; /* Для позиционирования подписи */
    width: 100%;
    box-sizing: border-box;
}

.poems__image {
    width: 100%;
    height: auto;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #a0cfee;
}

.poems__image__caption {
    position: absolute;
    left: 10px; /* Отступ от левого края */
    bottom: 10px; /* Отступ от нижнего края */
    font-size: 16px;
    color: #ffffff; /* Белый текст */
    background-color: rgba(0, 0, 0, 0.6); /* Полупрозрачный черный фон */
    padding: 10px;
    border-radius: 5px;
    max-width: calc(100% - 20px); /* Чтобы подпись не выходила за рамки контейнера */
    box-sizing: border-box;
}

.poems__block__poem {
    font-family: 'Caveat', cursive;
    font-size: 22px;
    color: #444;
    line-height: 1.6;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #a0cfee;
    width: fit-content; /* Возвращаем ширину по содержимому */
    box-sizing: border-box;
    margin-bottom: 20px; /* Добавляем отступ внизу для прокрутки */
}

.poems__block__poem p {
    font-family: 'Caveat', cursive;
    text-align: left; /* Текст выровнен по левому краю */
    margin: 0; /* Убираем отступы */
    max-width: 600px; /* Задаем максимальную ширину текста */
}
