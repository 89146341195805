.family-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.family-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.family-title {
    font-size: 2.5em;
    font-weight: bold;
}

.family-note {
    background-color: #ffcccc;
    border: 1px solid #ff9999;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    max-width: 50%;
    margin-left: 20px;
    font-family: 'Trebuchet MS';
}

.family-main {
    display: flex;
    margin-top: 20px;
    justify-content: center;
}

.family-column {
    /* flex: 1; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    width: 40%;
}

.block__text, .block__image {
    width: 100%;
    margin-bottom: 20px;
}

.family-content-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.family-content-row .block__text {
    margin-left: 20px;
}
