.difference3-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px;
    text-align: center;
    background-image: url('assets/Difference10.jpg');
    background-size: cover;
    background-position: center;
}

.difference3-header {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 30px;
    font-weight: bold;
}

.difference3-media {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(240, 248, 255, 0.5);
    padding: 10px;
    border-radius: 20px;
    width: 75%;
    height: 50vh;
}

.media-element-df3 {
    height: 100%;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
