/* Timeline.css */
.text-div {
    margin-bottom: 200px;
    align-items: center;
}
.main-home {
    vertical-align: top;
    display: inline-block;
    background-image: url('assets/icons/home.png');
    background-size: 100% auto;
    width: 45%;
    height: 450px;
}
.street-lamp {
    display: inline-block;
    background-image: url('assets/icons/street_lamp.png');
    background-size: auto 450px;
    width: 175px;
    height: 450px;
}
.second-text {
    padding: 20px;
    border-radius: 20px;
    vertical-align: top;
    margin-top: 15%;
    border: gold 2px solid;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.timeline-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Высота 100% видимой части экрана */
}

.timeline-container {
    position: absolute;
    width: 99%;
    padding: 50px 0;
}

.timeline-line {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #333;
    z-index: 1;
}

.events-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
}

.timeline-event {
    position: relative;
    width: 40%;
    text-align: center;
    z-index: 2;
}

.timeline-event.top .event-content {
    transform: translateY(-60%);
    margin-bottom: 20px; /* Добавлен отступ снизу */
}

.timeline-event.bottom .event-content {
    transform: translateY(60%);
    margin-top: 20px; /* Добавлен отступ сверху */
}

.event-content {
    background-color: rgb(255, 235, 119);
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
}

.event-date {
    font-family: 'Trebuchet MS';
    font-weight: bold;
    margin-bottom: 5px;
}

.event-description {
    margin-bottom: 10px;
}

.event-image {
    display: inline-block;
    width: 100%;
    margin-right: 5px;
    max-width: 150px;
    border-radius: 8px;
}

.event-dot {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background-color: #333;
    border-radius: 50%;
    z-index: 3;
}
