/* src/Begin.css */

.block {
    width: 100%;
    padding: 50px;
    box-sizing: border-box;
}

.content {
    display: flex;
    justify-content: center; /* Центрирование контейнеров по горизонтали */
    align-items: center; /* Центрирование контейнеров по вертикали */
    flex: 1; /* Позволяет контенту занимать оставшуюся высоту экрана */
    padding: 20px; /* Добавляем внутренние отступы */
    box-sizing: border-box;
    gap: 20px; /* Отступ между контейнерами */
}

.text-image-container {
    width: 35%;
    flex-direction: column;
    align-items: center; /* Выравнивание по центру для текстов и изображений в контейнере */
}

.reversed {
    align-items: center; /* Выравнивание по центру для текстов и изображений в перевёрнутом контейнере */
}

/* Общие стили для блоков текста */
.block__text {
    font-size: 20px;
    color: #333;
    line-height: 1.5;
    background-color: #ffffff; /* Более темный цвет фона */
    padding: 30px;
    border-radius: 15px; /* Скругленные углы */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Добавление тени */
    border: 1px solid #a0cfee; /* Рамка */
    margin-bottom: 10px; /* Отступ снизу для текста в контейнере */
    width: 100%; /* Ширина текста */
    box-sizing: border-box;
}

/* Общие стили для изображений */
.block__image {
    max-width: 100%; /* Для подстраховки, чтобы изображение не было больше своего родительского контейнера */
    height: auto;
    border-radius: 15px; /* Скругление углов */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Добавление тени */
    width: 100%; /* Ширина фото */
}

/* Стили для горизонтальных блоков текста и фото */
.block__horizontal__text,
.block__horizontal__photo {
    width: 100%; /* Ширина текста и фото для горизонтальных элементов */
}

/* Стили для вертикальных блоков текста и фото */
.block__vertical__text,
.block__vertical__photo {
    width: 100%; /* Ширина текста и фото для вертикальных элементов */
}
.walk4 {
    width: 30%; 
    margin: 20px; 
    padding: 10px; 
    background-color: rgb(255, 255, 255, 0.5); 
    border-radius: 20px
}
.img4 {
    width: 49%;
    height: auto;
    border-radius: 20px;
}
.walk5 {
    display: flex; 
    flex-direction: column; 
    align-items: center;
    width: 40%;
    margin: 10px; 
    background-color: rgb(255,255,255, 0.5);
    border-radius: 20px; 
    padding: 10px;
}
.img5 {
    width: 49%; 
    height: auto;
    border-radius: 20px;
}
.row5 {
    display: flex; 
    justify-content: space-between; 
    width: 100%;
}

p {font-family: 'Trebuchet MS'}
