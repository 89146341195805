.media-container-spb {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #bcef8f;
    min-height: 100vh;
}

.video {
    width: 100%; /* Настроить по необходимости */
    height: auto;
    box-sizing: border-box;
    padding: 10px;
    background-color: rgb(255, 255, 255, 0.5); 
    border-radius: 20px;
}

.photos {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: rgb(255, 255, 255, 0.5); 
    border-radius: 20px;
}

.photo {
    width: 49%;
    margin-bottom: 10px;
    border-radius: 20px;
}

.photo:last-child {
    margin-bottom: 0;
}
