#homepage-container {
    background-color: bisque;
    height: 100%;
}
.homeheader {
    color: black; 
    font-size: 40px;
}
.subtext { font-size: 20px }
.header-container, .links {
    width: 50%;
    box-sizing: border-box;
}
.header-container {
    display: flex;
    background-color: rgba(255, 255, 255, 0.5);
    padding-left: 20px;
    border-radius: 20px;
}
.filter {
    box-sizing: border-box; 
    padding: 20px; 
    width: 100%; 
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    backdrop-filter: blur(10px);
}
.link {
    font-size: 15px;
}
.links { 
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    padding: 2%;
    margin-top: 20px;
}
.button {
    padding-right: 10px;
    padding-left: 10px;
    background-color: gainsboro;
    border-radius: 10px;
    font-weight: bold;
    font-size: 25px;
    width: fit-content;
}

.dinosaur {
    border-radius: 20px;
    height: 25vh;
}
.shape {
    height: 90px;
    width: 1px;
    border: black 2px dashed;
    transform: skew(65deg);
    margin-left: 50%;
  }
.reverse {
    transform: skew(-65deg);
}
.point, .point-right {
    display: inline-block;
    background-color: rgb(200,200,200);
    width: 30%; 
    margin: 2px; 
    padding: 1%; 
    text-align: right;
}
.point-right {
    margin-left: 78%;
}