.round-videos {
    min-height: 100vh;
    text-align: center;
    padding: 20px;
    background-color: #f0f0f0; /* Изменение цвета фона на пастельно-серый светлый оттенок */
}

.page-title {
    font-size: 36px;
    color: #333;
    margin-bottom: 40px;
}

.video-row {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-bottom: 50px;
}

.round-video-container {
    width: 20vw;
    height: 20vw;
    text-align: center;
    cursor: pointer;
}

.round-video {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
}
